import "./global.css";
import "./App.css";
import { useEffect, useState } from "react";

function App() {
  const headers = ["サービス", "5月26日", "27日", "28日", "29日", "30日", "31日", "6月1日", "2日"];

  const initialData = [
    {
      name: "sharemore_api",
      status: ["✔️", "✔️", "✔️", "✔️", "✔️", "✔️", "✔️", "✔️"],
    },
    {
      name: "sharemore_backend",
      status: ["✔️", "✔️", "✔️", "✔️", "✔️", "✔️", "✔️", "✔️"],
    },
    {
      name: "sharemore_logging",
      status: ["✔️", "✔️", "✔️", "✔️", "✔️", "✔️", "✔️", "✔️"],
    },
  ];

  const AvailableStatusIcon = () => (
    <svg aria-label="Available status" fill="none" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9.001.666A8.336 8.336 0 0 0 .668 8.999c0 4.6 3.733 8.334 8.333 8.334s8.334-3.734 8.334-8.334S13.6.666 9 .666Zm0 15a6.676 6.676 0 0 1-6.666-6.667A6.676 6.676 0 0 1 9 2.333a6.676 6.676 0 0 1 6.667 6.666A6.676 6.676 0 0 1 9 15.666Zm-1.666-4.833L5.168 8.666 4.001 9.833l3.334 3.333L14 6.499l-1.166-1.166-5.5 5.5Z"
        fill="#1E8E3E"
        fillRule="evenodd"
      ></path>
    </svg>
  );

  const InformationStatusIcon = () => (
    <svg aria-label="Informational status" fill="none" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9.001.666A8.336 8.336 0 0 0 .668 8.999c0 4.6 3.733 8.334 8.333 8.334s8.334-3.734 8.334-8.334S13.6.666 9 .666Zm-.833 4.167v1.666h1.667V4.833H8.168Zm0 3.333v5h1.667v-5H8.168Zm-5.833.833A6.676 6.676 0 0 0 9 15.666a6.676 6.676 0 0 0 6.667-6.667A6.676 6.676 0 0 0 9 2.333a6.676 6.676 0 0 0-6.666 6.666Z"
        fill="#1967D2"
        fillRule="evenodd"
      ></path>
    </svg>
  );

  const DisruptionStatusIcon = () => (
    <svg aria-label="Disruption status" fill="none" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.168 11.5h1.667v1.666H8.168v-1.667Zm0-6.667h1.667v5H8.168v-5ZM8.993.666C4.393.666.668 4.399.668 8.999s3.725 8.334 8.325 8.334c4.608 0 8.342-3.734 8.342-8.334S13.6.666 8.993.666Zm.008 15a6.665 6.665 0 0 1-6.666-6.667A6.665 6.665 0 0 1 9 2.333a6.665 6.665 0 0 1 6.667 6.666A6.665 6.665 0 0 1 9 15.666Z"
        fill="#E37400"
        fillRule="evenodd"
      ></path>
    </svg>
  );

  const OutageStatusIcon = () => (
    <svg aria-label="Outage status" fill="none" height="18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M11.16 5.666 9 7.824 6.843 5.666 5.668 6.841l2.158 2.158-2.158 2.159 1.175 1.175 2.158-2.159 2.159 2.159 1.175-1.175-2.159-2.159 2.159-2.158-1.175-1.175ZM9 .666A8.326 8.326 0 0 0 .668 8.999a8.326 8.326 0 0 0 8.333 8.334 8.326 8.326 0 0 0 8.334-8.334A8.326 8.326 0 0 0 9 .666Zm0 15a6.676 6.676 0 0 1-6.666-6.667A6.676 6.676 0 0 1 9 2.333a6.676 6.676 0 0 1 6.667 6.666A6.676 6.676 0 0 1 9 15.666Z"
        fill="#D93025"
        fillRule="evenodd"
      ></path>
    </svg>
  );

  const [lastUpdated, setLastUpdated] = useState<string>("");

  useEffect(() => {
    const now = new Date();
    const formattedDate = now.toLocaleString("ja-JP", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    setLastUpdated(formattedDate);
  }, []);

  return (
    <main className="flex min-h-screen flex-col items-center justify-between p-24">
      <div className="z-10 w-full max-w-5xl items-center justify-between font-mono text-sm lg:flex"></div>
      <div className="min-h-screen flex flex-col items-center bg-[#f0f2f5] p-4">
        <div className="mb-8">
          <p className="text-3xl font-bold">
            <span className="text-red-500">S</span>
            <span className="text-orange-500">h</span>
            <span className="text-yellow-500">a</span>
            <span className="text-green-500">r</span>
            <span className="text-blue-500">e</span>
            <span className="text-indigo-500">A</span>
            <span className="text-purple-500">p</span>
            <span className="text-pink-500">p</span>
            <span className="text-red-500">V</span>
            <span className="text-orange-500">i</span>
            <span className="text-yellow-500">s</span>
            <span className="text-green-500">u</span>
            <span className="text-blue-500">a</span>
            <span className="text-indigo-500">l</span>
          </p>
          <br />
          <h2 className="text-xl text-center text-[#333] font-bold">ShareAppVisual ステータス ダッシュボード</h2>
          <p className="text-center text-[#333] mb-4 font-roboto">このページには、ShareAppVisual に含まれるサービスのステータス情報が掲載されます。</p>
        </div>
        <div className="p-8 bg-white shadow-lg rounded-lg w-full max-w-6xl">
          <div className="flex mb-4 text-xs items-center">
            <span className="flex items-center mr-4">
              <AvailableStatusIcon />
              利用可能
            </span>
            <span className="flex items-center mr-4">
              <InformationStatusIcon />
              サービス情報
            </span>
            <span className="flex items-center mr-4">
              <DisruptionStatusIcon />
              サービス障害
            </span>
            <span className="flex items-center">
              <OutageStatusIcon />
              サービス停止
            </span>
            <span className="ml-auto text-xs text-gray-500">最終更新日時: {lastUpdated}</span>
          </div>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index} className="py-2 px-4 border-r bg-[#f7f7f7] text-left text-sm font-semibold text-[#333] border-b">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {initialData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className="py-2 px-4 border-b border-r">{row.name}</td>
                  {row.status.map((status, statusIndex) => (
                    <td key={statusIndex} className="py-2 px-4 border-b border-r text-center">
                      {status === "✔️" ? <AvailableStatusIcon /> : status}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default App;
